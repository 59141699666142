import z from "zod"

export const enum SCHEMA_ERRORS {
  REQUIRED = "REQUIRED",
}

export const reportsSchema = z.object({
  orientedBy: z.enum(["sensor", "asset"]),
  startDate: z.string().min(1, SCHEMA_ERRORS.REQUIRED),
  endDate: z.string().min(1, SCHEMA_ERRORS.REQUIRED),
})

export type ReportsSchema = z.infer<typeof reportsSchema>
