import { Skeleton } from "@mui/material"
import { Container } from "./styles"

interface InputSkeletonProps {
  label?: boolean
}

export const InputSkeleton = ({ label = true }: InputSkeletonProps) => {
  return (
    <Container>
      {label && <Skeleton variant='text' width={64} height={18} />}
      <Skeleton variant='rounded' width={300} height={48} />
    </Container>
  )
}
