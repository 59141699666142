import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const TextWrapper = styled.div`
  padding: 12px 0;
`

export const ServicesWrapper = styled.div`
  overflow-x: auto;
  width: 300px;
  height: 100%;
  padding: 12px 12px;

  @media ${media.small} {
    width: auto;
    padding: 12px 0;
  }
`

export const ServiceWrapper = styled.div<{ $padding: boolean }>`
  padding-right: ${({ $padding }) => ($padding ? "12px" : "0")};
`

export const DisabledContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  visibility: hidden;
`

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  .tooltip {
    display: none;
  }

  @media ${media.small} {
    &:hover .tooltip {
      display: flex;
    }
  }
`

export const TooltipWrapper = styled.div`
  display: none;
  position: absolute;
  z-index: 999999999;
`

export const Tooltip = styled.div`
  white-space: nowrap;

  display: flex;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  padding: 8px;

  @media ${media.small} {
    position: absolute;

    width: auto;

    z-index: 99;
    top: 24px;
    right: -54px;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`
