import styled from "styled-components"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;

  padding: 0 12px 24px 12px;

  @media (min-width: 1300px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0 12px 0 0;
  }
`
