import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  height: 100%;
  width: 100%;

  @media ${media.large} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;

  @media ${media.medium} {
    flex-direction: row;
    align-items: end;
  }
`

export const InputWrapper = styled.div`
  width: 100%;

  @media ${media.extraLarge} {
    width: 220px;
  }
`
