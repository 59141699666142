import {
  Container,
  Root,
  Header,
  Body,
  HeaderRow,
  HeaderCell,
} from "./table.styled"
import { CellRow } from "./components/CellRow"
import { Text } from "../ui/Text"
import { FooterSkeleton } from "../Table/components/Footer"
import { ReactNode } from "react"

export interface Column<T> {
  label: string
  render: (param: T) => ReactNode
}

export interface TableProps<T> {
  columns: Column<T>[]
  data: number
  checkbox?: boolean
}

export const TableSkeleton = <
  T extends {
    id: number | string
  },
>({
  columns,
  checkbox = false,
  data,
}: TableProps<T>) => {
  return (
    <Container>
      <Root $checkbox={checkbox} $expand={false}>
        <Header>
          <HeaderRow $rowsize={columns.length}>
            {checkbox && <HeaderCell />}

            {columns.map((column, index) => {
              return (
                <HeaderCell key={index}>
                  <Text color='gray.800' fontSize='sm' fontWeight='medium'>
                    {column.label}
                  </Text>
                </HeaderCell>
              )
            })}
          </HeaderRow>
        </Header>

        <Body>
          {Array.from({ length: data }, (_, index) => index + 1).map(
            (row, index) => {
              return (
                <CellRow
                  key={index}
                  hasCheckbox={checkbox}
                  columns={columns as Column<{ id: number }>[]}
                  row={row}
                />
              )
            }
          )}
        </Body>
      </Root>

      <FooterSkeleton />
    </Container>
  )
}
