import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 2px;
  width: 100%;
`

export const Input = styled.input<{ $empty: boolean }>`
  width: 100%;
  height: 48px;

  border: 1px solid ${({ theme }) => theme.palette.gray[300]};

  border-radius: 4px;

  padding: 0 12px;

  color: ${({ $empty, theme }) =>
    $empty ? theme.palette.gray[500] : theme.palette.gray[800]};

  &::placeholder {
    color: ${(props) => props.theme.palette.gray[500]};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.md.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    line-height: ${(props) => props.theme.typography.text.md.lineHeight};
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &:disabled {
    &::placeholder {
      color: ${(props) => props.theme.palette.gray[400]};
    }

    p {
      color: ${(props) => props.theme.palette.gray[500]};
    }
  }
`
