import { Sensor } from "../../../../../utils/entities/assets"
import { Column, Table } from "../../../../../components/Table"
import { MessageError, TextWrapper } from "./styles"
import { useFetchUnlinkedSensorsQuery } from "../../../../../store/store"
import { useMemo, useState } from "react"
import { TableSkeleton } from "../../../../../components/TableSkeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { cleanAndPadSerialNumber } from "../../../../../utils/cleanAndPadSerialNumber"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { Text } from "../../../../../components/ui/Text"

interface SensorRow {
  id: number
  name: string
  serialNumber: string
}

interface RenderTableProps {
  search: string
  handleCheck: (id: number | undefined) => void
}

export const AssetSensorsTable = ({
  search,
  handleCheck,
}: RenderTableProps) => {
  const { assets } = useAppTranslate()

  const [page, setPage] = useState(1)

  const [limit, setLimit] = useState(5)
  const [offset, setOffset] = useState(0)
  const [orderBy, setOrderBy] = useState<"name" | "serial_number">("name")
  const [sortBy, setSortBy] = useState<"ASC" | "DESC">("ASC")

  const { data, isFetching, isLoading, isError } = useFetchUnlinkedSensorsQuery(
    {
      limit,
      offset,
      search,
      orderBy,
      sortBy,
    }
  )

  const rows: SensorRow[] = useMemo(() => {
    if (!data) return []

    return data.sensors.map((sensor: Sensor) => ({
      id: sensor.id,
      name: sensor.name,
      serialNumber: sensor.serialNumber,
    }))
  }, [data])

  const columns: Column<SensorRow>[] = [
    {
      id: "name",
      label: assets.sensors.name,
      render: ({ name, serialNumber }) => (
        <TextWrapper>
          <Text color='gray.700' fontSize='sm' fontWeight='regular'>
            {name ? name : `S${cleanAndPadSerialNumber(serialNumber)}`}
          </Text>
        </TextWrapper>
      ),
      order: true,
    },
    {
      id: "serial_number",
      label: assets.sensors.serialNumber,
      render: ({ serialNumber }) => (
        <TextWrapper>
          <Text color='gray.700' fontSize='sm' fontWeight='regular'>
            {serialNumber}
          </Text>
        </TextWrapper>
      ),
      order: true,
    },
  ]

  const handleChangeItemsPerPage = (items: number) => {
    setLimit(items)
    setOffset(0)
    handleCheck(undefined)
  }

  const handleChangePage = (page: number) => {
    setPage(page)
    setOffset(limit * (page - 1))
  }

  const handleChangeOrderBy = (orderBy: string) => {
    setOrderBy(orderBy as "name" | "serial_number")
    setSortBy((state) => (state === "ASC" ? "DESC" : "ASC"))
  }

  if (isLoading || isFetching)
    return (
      <FlexContainer gap={12} fullWidth>
        <TableSkeleton columns={columns} data={2} checkbox={true} />
      </FlexContainer>
    )

  if (isError || !data) {
    return (
      <FlexContainer fullWidth>
        <MessageError>{assets.sensors.error}</MessageError>
      </FlexContainer>
    )
  }

  return (
    <Table
      emptyMessage={assets.sensors.emptyLabel}
      columns={columns}
      data={rows}
      total={data.totalUnlinkedSensors}
      itemsPerPage={limit}
      page={page}
      orderBy={orderBy}
      sortAsc={sortBy === "ASC"}
      onChangeItemsPerPage={handleChangeItemsPerPage}
      onChangePage={handleChangePage}
      selectionType='radio'
      onCheck={(id) => handleCheck(Number(id))}
      onChangeOrderBy={handleChangeOrderBy}
      checkbox
    />
  )
}
