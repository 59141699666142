import { ArrowLeft, ArrowRight } from "phosphor-react"
import { IconButton } from "../../../ui/IconButton"
import { Container, Selector, Pages, Counter, Item, Page } from "./styles"
import { useMemo } from "react"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { FooterSkeleton } from "./Footer.skeleton"

interface FooterProps {
  total: number
  itemsPerPage: number
  page: number
  onChangeItemsPerPage: (items: number) => void
  onChangePage: (page: number) => void
  onChangeData: (itemsPerPage: number, page: number) => void
}

const ITEMS_PER_PAGE = [5, 10]

const getValues = (size: number): number[] => {
  return Array.from({ length: size }, (_, k) => k + 1)
}

const Footer = ({
  total,
  itemsPerPage,
  page: selected,
  onChangeItemsPerPage,
  onChangePage,
  onChangeData,
}: FooterProps) => {
  const { table } = useAppTranslate()

  const pages = useMemo(
    () => Math.ceil(total / itemsPerPage),
    [total, itemsPerPage]
  )

  const handleChangePage = (page: number) => {
    if (page >= 1 && page <= pages) {
      onChangePage(page)
      onChangeData(itemsPerPage, page)
    }
  }

  const handleChangeItemsPerPage = (items: number) => {
    onChangeItemsPerPage(items)
    handleChangePage(0)
  }

  return (
    <Container>
      <div className='pagination'>
        <Selector>
          <p>{table.show}</p>
          <select
            onChange={(e) => handleChangeItemsPerPage(Number(e.target.value))}
            defaultValue={itemsPerPage}
          >
            {ITEMS_PER_PAGE.map((v) => (
              <option key={v}>{v}</option>
            ))}
          </select>
          <p>{table.itemsPerPage}</p>
        </Selector>
        <Pages>
          {pages > 0 && (
            <IconButton
              variant='secondary'
              icon={<ArrowLeft size={20} />}
              onClick={() => handleChangePage(selected - 1)}
              disabled={selected === 1}
            />
          )}

          <Counter>
            {pages <= 7 &&
              getValues(pages).map((v) => (
                <Page
                  key={v}
                  $selected={selected === v}
                  onClick={() => handleChangePage(v)}
                >
                  {v}
                </Page>
              ))}

            {selected < 5 && pages > 7 && (
              <>
                {getValues(pages)
                  .slice(0, 5)
                  .map((v) => (
                    <Page
                      key={v}
                      $selected={selected === v}
                      onClick={() => handleChangePage(v)}
                    >
                      {v}
                    </Page>
                  ))}
                <Item>...</Item>
                <Page
                  $selected={selected === pages}
                  onClick={() => handleChangePage(pages)}
                >
                  {pages}
                </Page>
              </>
            )}

            {selected >= 5 && selected <= pages - 4 && pages > 7 && (
              <>
                <Page
                  $selected={selected === 1}
                  onClick={() => handleChangePage(1)}
                >
                  1
                </Page>

                <Item>...</Item>

                <Item onClick={() => handleChangePage(selected - 1)}>
                  {selected - 1}
                </Item>

                <Page $selected={true}>{selected}</Page>

                <Item onClick={() => handleChangePage(selected + 1)}>
                  {selected + 1}
                </Item>

                <Item>...</Item>

                <Page
                  $selected={selected === pages}
                  onClick={() => handleChangePage(pages)}
                >
                  {pages}
                </Page>
              </>
            )}

            {selected > pages - 4 && pages > 7 && (
              <>
                <Page
                  $selected={selected === 1}
                  onClick={() => handleChangePage(1)}
                >
                  {1}
                </Page>
                <Item>...</Item>
                {getValues(pages)
                  .slice(pages - 5, pages)
                  .map((v) => (
                    <Page
                      key={v}
                      $selected={selected === v}
                      onClick={() => handleChangePage(v)}
                    >
                      {v}
                    </Page>
                  ))}
              </>
            )}
          </Counter>
          {pages > 0 && (
            <IconButton
              variant='secondary'
              icon={<ArrowRight size={20} />}
              onClick={() => handleChangePage(selected + 1)}
              disabled={selected === pages}
            />
          )}
        </Pages>
      </div>
    </Container>
  )
}

export { Footer, FooterSkeleton }
