import styled from "styled-components"

export const Period = styled.div`
  flex-direction: column;
  font-size: large;
`
export const PeriodData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  padding: 25px 10px 25px 10px;

  border-top: 0.5px solid #c4c4c4;
`

export const Title = styled.h3`
  font-size: medium;
`

export const OrbitSelector = styled.div`
  font-size: small;

  h3 {
    border-bottom: 0.5px solid #c4c4c4;
    margin-bottom: 5px;
  }

  gap: 10px;
`

export const ChartType = styled.div`
  font-size: small;
`

export const ChartTypeData = styled.div`
  border-top: 0.5px solid #c4c4c4;
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  gap: 16px;
  justify-content: center;
`
export const Sensors = styled.div`
  font-size: small;
`

export const SensorsData = styled.div`
  border-top: 0.5px solid #c4c4c4;
`

export const MenuFooter = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;

  .buttons {
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const Button = styled.div`
  height: 38px;
  margin-right: 10.5px;
  margin-left: 10px;
  background-color: #fff;
  color: black;
`

export const ContainerSensorsAndSearch = styled.div`
  background-color: #ffff;
  display: flex;
  justify-content: space-between;
`

export const UserSelectionContainer = styled.div`
  justify-content: end;
  display: flex;
  flex-direction: row;
  width: 45%;
  align-items: center;
  gap: 1%;
  margin-bottom: 1%;
  margin-left: 5%;
  @media (max-width: 700px) {
    flex-wrap: wrap;
  }
`

export const UserSelectionDropDownAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`

export const UserSelectionDropdown = styled.div`
  display: flex;
  width: 90px;
  height: 23px;
  position: relative;
  justify-content: center;
  border: none;
  border-bottom: 1px solid grey;
`

export const UserSelectionDropDownText = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UserSelectionDropdownList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: blue;
  position: absolute;
  top: 23px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

export const UserSelectionDropdownListItem = styled.button<{ index: number }>`
  background: ${(props) => (props.index % 2 === 0 ? "#f0ebeb" : "#d8d3d3")};
  border: none;
  cursor: pointer;
  padding: 2%;
  border-radius: 0px !important;
  &:hover {
    background: #9ffecb;
  }
`

export const ResetBtn = styled.button`
  border: none;
  border-radius: 5px;
  background: #ff7b7b21;
  color: #ff7b7b;
  text-transform: uppercase;
  padding: 6px;

  &:hover {
    color: white;
    background: #ff7b7b;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`

export const InputSearch = styled.input`
  margin-bottom: 7px;
  padding: 6px;
  width: 120px;
  border: none;
  border-bottom: 1px solid grey;
  justify-content: center;
  overflow: hidden;
`
export const TextNotFound = styled.div`
  text-align: center;
  padding-top: 20px;
  font-size: 1.2em;
`
