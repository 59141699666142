/* eslint-disable @typescript-eslint/no-explicit-any */
import { SensorConfiguredDTO } from "./alarms_logs.interfaces"

const HDR_SERVICE_TYPE = [
  "health",
  "temp",
  "tempMMM",
  "rms2",
  "rmms",
  "tilt",
  "fft",
  "accRaw",
  "gps",
  "_4t20",
  "ntc",
  "pot",
]

export function buildConfiguredSensors(configurations: any) {
  const obj = [] as SensorConfiguredDTO[]

  try {
    configurations.forEach((item: any) => {
      if (obj.some((e) => e.id === item.id)) {
        obj
          .filter((e) => e.id === item.id)
          .forEach((objMapped) => {
            let temporaryArray: any[] = []
            let temporaryServicesArray = objMapped.services

            if (!temporaryServicesArray) temporaryServicesArray = []

            temporaryArray =
              _verifyWhichServicesSensorHasAndReturnAnArrayWithThem(
                temporaryArray,
                temporaryServicesArray,
                objMapped,
                item
              )

            if (temporaryArray.length > 0) {
              temporaryArray.forEach((tempArrayItem) => {
                const { id } = tempArrayItem
                let index = obj.findIndex((x) => x.id === id)
                obj.splice(index, 1)
                obj.push(tempArrayItem)
              })
            }
          })
      } else {
        let tempObjectBase = {
          id: item.id,
          name: item.name,
          mac: item.mac,
          productionSerialNumber: item.productionSerialNumber,
          hardwareVersion: item.hardwareVersion,
        }

        let tempServicesArray: any[] = []

        tempObjectBase = _verifyWhichServicesSensorHasAndReturnAnArrayWithThem(
          null,
          tempServicesArray,
          tempObjectBase,
          item
        )

        obj.push(tempObjectBase)
      }
    })
  } catch (error) {
    console.error(error)
  }

  return obj
}

function _verifyWhichServicesSensorHasAndReturnAnArrayWithThem(
  tempArrayToReturn: any,
  tempServicesArray: any,
  objMapped: any,
  sensorFromAPI: any
) {
  HDR_SERVICE_TYPE.forEach((service) => {
    if (sensorFromAPI[`${service}`]) {
      tempServicesArray.push(service)
      objMapped = {
        ...objMapped,
        [service]: sensorFromAPI[`${service}`],
        services: tempServicesArray,
      }
      if (tempArrayToReturn !== null) tempArrayToReturn.push(objMapped)
    }
  })

  return tempArrayToReturn ?? objMapped
}
