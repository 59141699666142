import { createAsyncThunk } from "@reduxjs/toolkit"
import { apiCall } from "../../../utils/axios"
import { SensorFromStoreDTO } from "./reports.interfaces"

import {
  buildSensorsProvObject,
  buildSensorWithDataObject,
} from "./reports.sensors"
import { request } from "./reports.request"
import { sensorRightJson } from "../../../pages/Reports/entities/sensorRightJson"
import { downloadCSV } from "./reports.csv"
import { SensorWithServicesDTO } from "../../../utils/entities/sensors/SensorsWithServicesDTO"

enum API_ROUTES {
  fetchProvisionedSensors = "/v1/proteu/sensors/prov/external",
}

export const fetchSensorWithServices = createAsyncThunk(
  "reports/fetchSensorWithServices",
  async (): Promise<SensorWithServicesDTO[]> => {
    const api = apiCall()
    const response = await api.get(API_ROUTES.fetchProvisionedSensors)
    return buildSensorWithDataObject(response.data)
  }
)

export const fetchProvisionedSensorsRequest = createAsyncThunk(
  "reports/fetchProvisionedSensorsRequest",
  async (): Promise<SensorFromStoreDTO> => {
    const api = apiCall()
    const response = await api.get(API_ROUTES.fetchProvisionedSensors)
    return buildSensorsProvObject(response.data)
  }
)

export const reportRequest = createAsyncThunk(
  "reports/reportRequest",
  async (payload: {
    startDate: string
    endDate: string
    sensors: sensorRightJson
  }): Promise<void | null> => {
    const csvData = await request(payload)

    if (!csvData) return null

    downloadCSV(csvData)
  }
)
