import { useAppTranslate } from "../../../../translate/useAppTranslate"

enum AssetTypeId {
  ORGANIZATION = 1,
  ADL = 2,
  LOCAL = 3,
  MOTOR = 4,
}

/**
 * Custom hook that manages the default asset properties (common to all organizations).
 *
 * @returns {Object} An object containing utility functions.
 * @returns {Function} getDefaultPropertyName - Function to get the default property name based on the asset type ID.
 * @returns {Function} getPropertyTranslation - Function to translate a property based on the asset type ID.
 */
export const useProperties = (): {
  getDefaultPropertyName: (typeId: number) => string | null
  getPropertyTranslation: (typeId: number, property: string) => string | null
} => {
  const { assets } = useAppTranslate()

  /**
   * Gets the default property name based on the asset type ID.
   * @param typeId - The ID representing the type of asset.
   * @returns {string | null} The default property name or null if the asset type ID is not recognized.
   */
  const getDefaultPropertyName = (typeId: number): string | null => {
    switch (typeId) {
      case AssetTypeId.ORGANIZATION:
        return assets.properties[1].name
      case AssetTypeId.ADL:
        return assets.properties[2].name
      case AssetTypeId.LOCAL:
        return assets.properties[3].name
      case AssetTypeId.MOTOR:
        return assets.properties[4].name
      default:
        return null
    }
  }

  /**
   * Translates a property based on the provided type ID.
   *
   * @param typeId - The ID representing the type of asset.
   * @param property - The property to be translated.
   * @returns {string | null} The translated property string or null if the type ID is not recognized.
   */
  const getPropertyTranslation = (
    typeId: number,
    property: string
  ): string | null => {
    switch (typeId) {
      case AssetTypeId.ORGANIZATION:
        return getOrganizationPropertiesTranslation(property)
      case AssetTypeId.ADL:
        return getAdlPropertiesTranslation(property)
      case AssetTypeId.LOCAL:
        return getLocalPropertiesTranslation(property)
      case AssetTypeId.MOTOR:
        return getMotorPropertiesTranslation(property)
      default:
        return null
    }
  }

  const getOrganizationPropertiesTranslation = (
    property: string
  ): string | null => null

  const getAdlPropertiesTranslation = (property: string): string | null => null

  const getLocalPropertiesTranslation = (property: string): string | null => {
    switch (property) {
      case "location":
        return assets.properties[3].location
      case "area":
        return assets.properties[3].area
      case "city":
        return assets.properties[3].city
      default:
        return null
    }
  }

  const getMotorPropertiesTranslation = (property: string): string | null => {
    switch (property) {
      case "voltage":
        return assets.properties[4].voltage
      case "power":
        return assets.properties[4].power
      case "current":
        return assets.properties[4].current
      default:
        return null
    }
  }

  return { getDefaultPropertyName, getPropertyTranslation }
}
