import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { getAccessToken } from "../../../utils/getAccessToken"
import { transoformProvisionedSensors } from "./transforms"
import { Sensor } from "./entities"

export const sensorsApi = createApi({
  reducerPath: "sensorsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers) => {
      const token = getAccessToken()

      if (token) headers.set("Authorization", `Bearer ${token}`)

      return headers
    },
  }),

  endpoints: (build) => ({
    fetchProvisionedSensors: build.query<Sensor[], void>({
      query: () => "/v1/proteu/sensors/prov/external",
      transformResponse: transoformProvisionedSensors,
    }),
  }),
})

export const { useFetchProvisionedSensorsQuery } = sensorsApi
