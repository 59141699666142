import Button from "@material-ui/core/Button"
import RadioButtonItem from "./components/RadioButtonItem"

import {
  MenuFooter,
  Period,
  ChartType,
  Sensors,
  PeriodData,
  ChartTypeData,
  SensorsData,
  OrbitSelector,
  ContainerSensorsAndSearch,
  UserSelectionContainer,
  UserSelectionDropDownAndIcon,
  UserSelectionDropdown,
  UserSelectionDropdownList,
  UserSelectionDropdownListItem,
  UserSelectionDropDownText,
  ResetBtn,
  InputSearch,
  TextNotFound,
  Title,
} from "./styles"

import { useConfigMenu } from "../../controllers/useConfigMenu"
import { useContext } from "react"
import { HistoricalAnalysisContext } from "../../context/modelContext"
import SensorsGroup from "./components/SensorsGroup"
import SelectOrbit from "./components/SelectOrbit"
import { ContextAnaliseHistorica } from "../../entities/contextDTO"
import { MdCancel, MdKeyboardArrowDown } from "react-icons/md"
import { DatetimeInput } from "../../../../components/ui/DatetimeInput"
import { Text } from "../../../../components/ui/Text"

const ConfigMenu = () => {
  const configMenuController = useConfigMenu()

  const {
    startDate,
    setStartDate,
    endDate,
    formGraphType,
    availablesServices,
    sensors,
    setFormGraphType,
    selectedOrbitItems,
    openFilterSelection,
    filteredSensors,
    FILTER_SENSOR_OPTIONS,
    searchInput,
    SERVICES,
    openFilterServices,
    setEndDate,
  } = useContext(HistoricalAnalysisContext) as ContextAnaliseHistorica

  const filter = (): JSX.Element => {
    return (
      <UserSelectionContainer>
        Pesquisar por :
        <UserSelectionDropDownAndIcon
          onClick={(e: React.MouseEvent<HTMLElement>) =>
            configMenuController.handleFilterDropdown(e)
          }
        >
          <UserSelectionDropdown>
            <UserSelectionDropDownText>
              {configMenuController.filterSelected}
            </UserSelectionDropDownText>
            {openFilterSelection && (
              <UserSelectionDropdownList>
                {FILTER_SENSOR_OPTIONS.map((option, index) => (
                  <UserSelectionDropdownListItem
                    key={index}
                    index={index}
                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                      configMenuController.handleFilterSelection(e, option)
                    }
                  >
                    {option}
                  </UserSelectionDropdownListItem>
                ))}
              </UserSelectionDropdownList>
            )}
          </UserSelectionDropdown>
        </UserSelectionDropDownAndIcon>
        {configMenuController.filterSelected === "Serviço" ? (
          <UserSelectionDropDownAndIcon
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              configMenuController.handleFilterServicesDropdown(e)
            }
          >
            <UserSelectionDropdown>
              <UserSelectionDropDownText>
                {configMenuController.filterServiceSelected}
              </UserSelectionDropDownText>
              {openFilterServices && (
                <UserSelectionDropdownList>
                  {SERVICES.map((option, index) => (
                    <UserSelectionDropdownListItem
                      key={index}
                      index={index}
                      onClick={(e) =>
                        configMenuController.handleFilterServiceSelection(
                          e,
                          option
                        )
                      }
                    >
                      {option}
                    </UserSelectionDropdownListItem>
                  ))}
                </UserSelectionDropdownList>
              )}
            </UserSelectionDropdown>

            <MdKeyboardArrowDown size={22} />
          </UserSelectionDropDownAndIcon>
        ) : (
          <InputSearch
            value={searchInput}
            disabled={configMenuController.filterSelected === ""}
            type='text'
            id='input'
            placeholder='Pesquisar...'
            onChange={(e) => configMenuController.searchItems(e.target.value)}
          />
        )}
        <ResetBtn
          title='Limpar pesquisa'
          onClick={configMenuController.clearFilter}
        >
          <MdCancel size={13} />
        </ResetBtn>
      </UserSelectionContainer>
    )
  }

  return (
    <>
      <Period>
        <Title>Período</Title>
        <PeriodData>
          <DatetimeInput
            placeholder='Data inicial'
            isEmpty={!startDate}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />

          <Text fontSize='md' fontWeight='semibold'>
            até
          </Text>

          <DatetimeInput
            placeholder='Data final'
            isEmpty={!endDate}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />
        </PeriodData>
      </Period>
      <ChartType>
        <Title>Tipo de Gráfico</Title>
        <ChartTypeData>
          <RadioButtonItem
            title='Amplitude/Tempo [s]'
            value={1}
            checked={formGraphType === 1}
            onChange={() => {
              configMenuController.clearSelectedServices()
              configMenuController.changeSelectFormGraphType(1)
            }}
          />

          <RadioButtonItem
            title='Amplitude/Frequência [hz]'
            value={2}
            checked={formGraphType === 2}
            onChange={() => {
              configMenuController.clearSelectedServices()
              configMenuController.changeSelectFormGraphType(2)
            }}
          />

          <RadioButtonItem
            title='Amplitude/Tempo [ms]'
            value={3}
            checked={formGraphType === 3}
            onChange={() => {
              configMenuController.clearSelectedServices()
              configMenuController.changeSelectFormGraphType(3)
            }}
          />

          <RadioButtonItem
            title='Horímetro'
            value={4}
            checked={formGraphType === 4}
            onChange={() => {
              configMenuController.clearSelectedServices()
              setFormGraphType(4)
            }}
          />

          <RadioButtonItem
            title='Orbita'
            value={5}
            checked={formGraphType === 5}
            onChange={() => {
              configMenuController.clearSelectedServices()
              setFormGraphType(5)
            }}
          />
        </ChartTypeData>
      </ChartType>

      {formGraphType === 5 && (
        <OrbitSelector>
          <Title>Eixos</Title>
          <SelectOrbit
            items={selectedOrbitItems}
            onChange={configMenuController.changeSelectedOrbit}
          />
        </OrbitSelector>
      )}

      <Sensors>
        <ContainerSensorsAndSearch>
          <Title>Sensores</Title>
          {filter()}
        </ContainerSensorsAndSearch>

        <SensorsData>
          {filteredSensors.length === 0 && searchInput.length !== 0 ? (
            <TextNotFound>
              Nenhum sensor encontrado para o{" "}
              {configMenuController.filterSelected}: &#39;{searchInput}&#39;
            </TextNotFound>
          ) : (
            <SensorsGroup
              availablesServices={
                filteredSensors.length === 0
                  ? availablesServices
                  : filteredSensors
              }
              sensors={sensors}
              graphicType={formGraphType}
              onChangeCheckedService={configMenuController.checkServiceSelected}
              onChangeSelectedServices={
                configMenuController.changeSelectedServices
              }
            />
          )}
        </SensorsData>
      </Sensors>
      <MenuFooter>
        <div className='buttons'>
          <Button
            variant='contained'
            onClick={() => configMenuController.clearSelectedServices()}
          >
            Reset
          </Button>

          <Button
            variant='contained'
            onClick={() => configMenuController.submitConfigs()}
          >
            Aplicar
          </Button>
        </div>
      </MenuFooter>
    </>
  )
}

export default ConfigMenu
