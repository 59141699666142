import { AdlAlarm, AlarmType } from "./entities"
import { ChartLine, ChartLineUp, ThermometerSimple } from "phosphor-react"
import { formatedDate } from "../../../utils/date"
import { ReactComponent as Threshold } from "../../../assets/Threshold.svg"
import theme from "../../../styles/theme"
import { toast } from "react-toastify"
import { ToastAlarm } from "../../../components/ui/ToastAlarm"

export const emitAlarm = ({
  title,
  backgroundColor,
  icon,
  content,
}: {
  title: string
  backgroundColor: string
  icon: JSX.Element
  content: Record<string, string>
}) => {
  toast(
    () => (
      <ToastAlarm
        backgroundColor={backgroundColor}
        icon={icon}
        title={title}
        content={content}
      />
    ),
    {
      autoClose: false,
      className: "toast-adl",
      style: {
        backgroundColor,
      },
    }
  )

  const alarmSound = new Audio(require("../../../assets/warning.mp3"))

  alarmSound.play().catch((error) => console.log(error))

  setTimeout(() => {
    alarmSound.pause()
  }, 3000)
}

export const NtcThresholdIcon = <ThermometerSimple weight='duotone' size={45} />

export const getADLAlarmContent = (data: AdlAlarm): Record<string, string> => {
  switch (data.alarmType) {
    case "AdlAlarmPoints":
      if (data.intensity === 1)
        return {
          Descrição: "Anomalia identificada",
          Intensidade: data.intensity.toString(),
          Local: data.sensorName,
          Data: formatedDate(data.time),
        }
      else
        return {
          Descrição: "Aumento da intensidade do alerta",
          Intensidade: data.intensity.toString(),
          Local: data.sensorName,
          Data: formatedDate(data.time),
        }
    case "ThresholdAlarm":
      return {
        Local: data.sensorName,
        Data: formatedDate(data.time),
      }
    default:
      return {
        Local: data.sensorName,
        Data: formatedDate(data.time),
      }
  }
}

export const getADLAlarmColor = (alarmType: AlarmType) => {
  switch (alarmType) {
    case "AdlAlarmPoints":
      return "#FF4500"
    case "ThresholdAlarm":
      return "#EAAA08"
    default:
      return "red"
  }
}

export const getADLAlarmTitle = (alarmType: AlarmType) => {
  switch (alarmType) {
    case "AdlAlarmPoints":
      return "Alteração na Tendência de Vibração"
    case "ThresholdAlarm":
      return "Vibração Acima do Limite Operacional"
    default:
      return "Alarme"
  }
}

export const getADLAlarmIcon = (alarmType: AlarmType) => {
  switch (alarmType) {
    case "AdlAlarmPoints":
      return (
        <ChartLineUp
          weight='duotone'
          size={60}
          color={theme.palette.gray[50]}
        />
      )
    case "ThresholdAlarm":
      return (
        <Threshold width={500} height={500} stroke={theme.palette.gray[50]} />
      )
    default:
      return (
        <ChartLine weight='duotone' size={60} color={theme.palette.gray[50]} />
      )
  }
}
