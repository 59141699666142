import { Container, Selector, Pages } from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

export const FooterSkeleton = () => {
  const { table } = useAppTranslate()

  return (
    <Container>
      <div className='pagination'>
        <Selector>
          <p>{table.show}</p>
          <select defaultValue={1}>
            <option>1</option>
          </select>
          <p>{table.itemsPerPage}</p>
        </Selector>

        <Pages>
          <div
            style={{
              height: 44,
              width: 44,
            }}
          />
        </Pages>
      </div>
    </Container>
  )
}
