import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Row = styled.tr<{ $rowsize: number; $checkbox: boolean }>`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.gray[200]};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};

  @media ${media.small} {
    td:first-child {
      padding-left: 16px;
      ${({ $checkbox }) => $checkbox && "width: auto"}
    }

    td:last-child {
      padding-right: 16px;
    }

    td {
      padding-left: 16px;
    }
  }
`

export const ContentRow = styled(Row)`
  display: none !important;

  @media ${media.small} {
    display: table-row !important;
  }
`

export const Cell = styled.td`
  background-color: ${(props) => props.theme.palette.gray[50]};
`

export const CheckboxCell = styled.td`
  width: 100%;

  display: flex !important;
  align-items: start !important;
  justify-content: start !important;

  padding: 12px 0 12px 12px;

  background-color: ${(props) => props.theme.palette.gray[50]};
`

export const ContentCell = styled(Cell)`
  &::before {
    display: none;
  }

  padding: 0px !important;

  @media ${media.small} {
    display: none !important;
  }
`

export const ExpandedRowCard = styled.td`
  width: 100%;

  padding: 0px !important;

  background-color: ${({ theme }) => theme.palette.gray[100]};
`

export const ExpandedCellCard = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`
