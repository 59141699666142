import { Text } from "../ui/Text"
import { CellRow } from "./components/CellRow"
import { useTable } from "./hooks/useTable"
import {
  Container,
  Root,
  Header,
  Body,
  HeaderRow,
  HeaderCell,
  EmptyBody,
} from "./table.styled"
import { Checkbox } from "../ui/Checkbox"
import { SnackBar } from "./components/SnackBar"
import { ReactNode } from "react"
import { Footer } from "./components/Footer"
import { HeaderTitle } from "./components/HeaderTitle"

export interface Column<T> {
  id: string
  label: string
  render: (param: T) => ReactNode
  order?: boolean
  icon?: ReactNode
}

export interface TableProps<T> {
  columns: Column<T>[]
  data: T[]
  total: number
  itemsPerPage: number
  page: number
  orderBy?: string
  sortAsc?: boolean
  onChangeItemsPerPage: (items: number) => void
  onChangePage: (page: number) => void
  onChangeOrderBy: (orderBy: string) => void
  checkbox?: boolean
  selectionType?: "checkbox" | "radio"
  expandedContent?: ReactNode
  emptyMessage: string
  onChangeData?: (itemsPerPage: number, page: number) => void
  onCheck: (id: string | number) => void
  defaultValue?: string | number
}

export const Table = <
  T extends {
    id: string | number
  },
>({
  columns,
  data,
  total,
  itemsPerPage,
  page,
  orderBy = "",
  sortAsc = false,
  onChangeItemsPerPage,
  onChangePage,
  onChangeOrderBy,
  checkbox = false,
  selectionType = "checkbox",
  emptyMessage,
  expandedContent,
  onChangeData = () => {},
  onCheck,
  defaultValue,
}: TableProps<T>) => {
  const hasExpandedContent = expandedContent !== undefined

  const {
    isEmpty,
    countChecked,
    check,
    getCheckState,
    uncheckAll,
    checkAll,
    expand,
    getExpandState,
  } = useTable<T>({ data, defaultValue, selectionType })

  return (
    <Container>
      <Root $checkbox={checkbox} $expand={hasExpandedContent}>
        <Header>
          <HeaderRow $rowsize={columns.length}>
            {checkbox && (
              <HeaderCell>
                {selectionType === "checkbox" && (
                  <Checkbox
                    checked={countChecked === data.length}
                    onChange={(e) => {
                      if (e.target.checked) checkAll()
                      else uncheckAll()
                    }}
                  />
                )}
              </HeaderCell>
            )}

            {columns.map((column, index) => {
              return (
                <HeaderTitle
                  key={index}
                  column={column}
                  selected={column.id === orderBy}
                  handleChangeOrderBy={onChangeOrderBy}
                  sortAsc={sortAsc}
                />
              )
            })}

            {hasExpandedContent && <HeaderCell />}
          </HeaderRow>
        </Header>

        {!isEmpty && (
          <Body>
            {data.map((row, index) => {
              return (
                <CellRow
                  key={index}
                  check={(id) => {
                    check(id)
                    onCheck(id)
                  }}
                  checked={checkbox ? getCheckState(row.id) : undefined}
                  expand={expand}
                  expanded={getExpandState(row.id)}
                  columns={columns}
                  row={row}
                  selectionType={selectionType}
                >
                  {expandedContent}
                </CellRow>
              )
            })}
          </Body>
        )}
      </Root>

      {isEmpty && (
        <EmptyBody>
          <Text color='gray.500' fontSize='sm' fontWeight='medium'>
            {emptyMessage}
          </Text>
        </EmptyBody>
      )}

      {countChecked !== 0 && selectionType !== "radio" && (
        <SnackBar count={countChecked} onClose={uncheckAll} />
      )}

      {total > 0 && (
        <Footer
          total={total}
          onChangeData={onChangeData}
          itemsPerPage={itemsPerPage}
          page={page}
          onChangeItemsPerPage={onChangeItemsPerPage}
          onChangePage={onChangePage}
        />
      )}
    </Container>
  )
}
