import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Title } from "../../../../../components/Title"
import { TitleAndText } from "../../../../../components/TitleAndText"
import { AssetHeader, Container, ImageAndDescription } from "./styles"
import { ImageContainer } from "../ImageContainer"
import { ModalHedro } from "../../../../../components/ModalHedro"
import { ConfigureAsset } from "../../Configure"
import { IconButton } from "../../../../../components/ui/IconButton"
import { Gear, Trash } from "phosphor-react"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import theme from "../../../../../styles/theme"
import { ModalDeleteAsset } from "../../Configure/components/ModalDeleteAsset"
import { useState } from "react"

interface AssetDescriptionProps {
  description: string
  asset: { id: number; name: string; isRoot: boolean }
}

export const AssetDescription = ({
  description,
  asset,
}: AssetDescriptionProps) => {
  const { assets } = useAppTranslate()

  const [isModalConfigureAssetOpen, setIsModalConfigureAssetOpen] =
    useState(false)

  const [isModalDeleteAssetOpen, setIsModalDeleteAssetOpen] = useState(false)

  return (
    <Container>
      {isModalConfigureAssetOpen && (
        <ModalHedro
          onClose={() => setIsModalConfigureAssetOpen(false)}
          title={assets.modal.editAsset}
          size='xl'
        >
          <ConfigureAsset
            id={asset.id}
            handleClose={() => setIsModalConfigureAssetOpen(false)}
          />
        </ModalHedro>
      )}

      {isModalDeleteAssetOpen && (
        <ModalDeleteAsset
          id={asset.id}
          handleOnClose={() => setIsModalDeleteAssetOpen(false)}
        />
      )}

      <AssetHeader>
        <Title label={asset.name} size='md' />
        <FlexContainer direction='row' gap={10}>
          <IconButton
            variant='secondary'
            icon={<Gear size={22} weight='bold' />}
            onClick={() => setIsModalConfigureAssetOpen(true)}
          />

          {!asset.isRoot && (
            <IconButton
              variant='secondary'
              icon={
                <Trash
                  color={theme.palette.error.main}
                  size={22}
                  weight='bold'
                />
              }
              onClick={() => setIsModalDeleteAssetOpen(true)}
            />
          )}
        </FlexContainer>
      </AssetHeader>
      <ImageAndDescription>
        <ImageContainer />
        <TitleAndText text={description} title={assets.details.description} />
      </ImageAndDescription>
    </Container>
  )
}
