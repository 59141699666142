import styled from "styled-components"

export const SortContainer = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  svg {
    color: ${({ $selected, theme }) =>
      $selected ? theme.palette.primary.main : theme.palette.gray[500]};
  }
`
