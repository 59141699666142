import { NewHeader } from "../../../components/NewHeader"
import { PageContent, PageWrapper } from "../../../components/ui/utils"
import { HeaderActions } from "./components/HeaderActions"
import { HeaderContainer } from "./styles"
import { SensorTable } from "./components/SensorsTable"
import { ReportsContextProvider } from "./context/ReportsContext"
import { useAppTranslate } from "../../../translate/useAppTranslate"

export const Reports = () => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.reports.title,
    subTitle: lateralMenu.routes.reports.download,
  }

  return (
    <ReportsContextProvider>
      <PageWrapper>
        <HeaderContainer>
          <NewHeader page={page} />
          <HeaderActions />
        </HeaderContainer>

        <PageContent>
          <SensorTable />
        </PageContent>
      </PageWrapper>
    </ReportsContextProvider>
  )
}
