import * as z from "zod"
import { AssetDetailsData } from "../../../../utils/entities/assets"

const MAX_FILE_SIZE = 5000000 // Max file size is 5MB

const ACCEPTED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
]

export const enum SCHEMA_ERRORS {
  INVALID_IMAGE_TYPE = "INVALID_IMAGE_TYPE",
  INVALID_FILE_SIZE = "INVALID_FILE_SIZE",
  REQUIRED = "REQUIRED",
}

export const assetSchema = z
  .object({
    name: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
    type: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
    description: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
    image: z
      .any()
      .refine((file: FileList) => {
        if (!file) return true
        if (file.length === 0) return true

        return file[0].size <= MAX_FILE_SIZE
      }, SCHEMA_ERRORS.INVALID_FILE_SIZE)
      .refine((file) => {
        if (!file) return true
        if (file.length === 0) return true

        return ACCEPTED_IMAGE_TYPES.includes(file[0].type)
      }, SCHEMA_ERRORS.INVALID_IMAGE_TYPE),
    properties: z.array(
      z.object<{ property: z.ZodString; value: z.ZodString }>({
        property: z.string(),
        value: z.string(),
      })
    ),
    sensorId: z.number().optional(),
    linkedAt: z.string(),
  })
  .superRefine(({ sensorId, linkedAt }, ctx) => {
    if (linkedAt && !sensorId) {
      ctx.addIssue({
        message: SCHEMA_ERRORS.REQUIRED,
        code: "too_small",
        path: ["sensorId"],
        minimum: 1,
        type: "string",
        inclusive: true,
      })
      return false
    }

    if (sensorId && !linkedAt) {
      ctx.addIssue({
        message: SCHEMA_ERRORS.REQUIRED,
        code: "too_small",
        path: ["linkedAt"],
        minimum: 1,
        type: "string",
        inclusive: true,
      })
      return false
    }
  })

export type AssetSchema = z.infer<typeof assetSchema>

export const defaultValues: AssetSchema = {
  name: "",
  type: "",
  image: null,
  description: "",
  properties: [],
  sensorId: undefined,
  linkedAt: "",
}

export const getDefaultValues = ({
  name,
  type,
  description,
  properties,
  sensor,
}: AssetDetailsData): AssetSchema => {
  return {
    name,
    type: type.id.toString(),
    image: null,
    description,
    properties: Object.keys(properties).map((key) => {
      return {
        property: key,
        value: properties[key],
      }
    }),
    sensorId: sensor?.id,
    linkedAt: sensor ? formatDate(sensor.linkedAt) : "",
  }
}

export const formatDate = (milliseconds: number): string => {
  const date = new Date(milliseconds)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")

  return `${year}-${month}-${day}T${hours}:${minutes}`
}
