import { PropsWithChildren } from "react"
import { Column } from "../.."

import { Row, ContentRow, Cell } from "./styles"
import { Skeleton } from "@mui/material"

interface CellRowProps<T> {
  columns: Column<T>[]
  row: number
  hasCheckbox: boolean
}

export const CellRow = <T extends { id: number }>({
  columns,
  hasCheckbox,
  children,
}: PropsWithChildren<CellRowProps<T>>) => {
  return (
    <>
      <Row $rowsize={columns.length} $checkbox={hasCheckbox}>
        {hasCheckbox && (
          <Cell>
            <Skeleton variant='circular' width={20} height={20} />
          </Cell>
        )}

        {columns.map((column) => (
          <Cell key={column.label} data-label={column.label}>
            <Skeleton
              variant='text'
              height={20}
              width={"100%"}
              style={{ marginRight: "12px" }}
            />
          </Cell>
        ))}
      </Row>

      <ContentRow $rowsize={1} $checkbox={hasCheckbox}>
        {children}
      </ContentRow>
    </>
  )
}
