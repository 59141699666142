import { FC } from "react"
import { useLoginController } from "./hooks/controller"
import { LoadingSpinner } from "../../components/LoadingSpinner"

import * as S from "./styles"

export const Login: FC = () => {
  const { error } = useLoginController()

  return (
    <S.Container>{error ? <div>{error}</div> : <LoadingSpinner />}</S.Container>
  )
}
