import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const TooltipWrapper = styled.div<{ $open: boolean; $mobile: boolean }>`
  display: ${({ $open, $mobile }) => ($open && $mobile ? "flex" : "none")};
  width: 100%;

  @media ${media.small} {
    position: absolute;
    width: auto;

    display: ${({ $open, $mobile }) => ($open && !$mobile ? "flex" : "none")};
  }
`

export const Tooltip = styled.div`
  white-space: nowrap;

  display: flex;
  width: 100%;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  padding: 8px;

  @media ${media.small} {
    position: absolute;

    width: auto;

    z-index: 99;
    top: 8px;
    right: 56px;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: start;
  align-items: start;
  gap: 8px;

  width: 100%;

  @media ${media.small} {
    grid-template-columns: repeat(6, 1fr);
  }
`
