import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import {
  onAdlAlarmCacheEntryAdded,
  onNtcThresholdAlarmCacheEntryAdded,
  onFilteredThresholdAlarmCacheEntryAdded,
} from "./updates"

export const adlApi = createApi({
  reducerPath: "adlApi",
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    listenADL: builder.query<void, number>({
      query: (_) => "adl/listenAlarms",
      onCacheEntryAdded: onAdlAlarmCacheEntryAdded,
    }),
    listenNTCThreshold: builder.query<void, number>({
      query: (_) => "adl/listenAlarms",
      onCacheEntryAdded: onNtcThresholdAlarmCacheEntryAdded,
    }),
    listenFilteredThreshold: builder.query<void, number>({
      query: (_) => "adl/listenAlarms",
      onCacheEntryAdded: onFilteredThresholdAlarmCacheEntryAdded,
    }),
  }),
})

export const {
  useListenADLQuery,
  useListenNTCThresholdQuery,
  useListenFilteredThresholdQuery,
} = adlApi
