import Row from "../../../../components/ui/Row"
import Column from "../../../../components/ui/Column"
import { AssetForm } from "../components/AssetForm"
import { useCreateAsset } from "./controllers/useCreateAsset"
import { AssetSchema } from "../utils/schema"

interface CreateAssetProps {
  assetId: number
  handleCancel: () => void
}

export const CreateAsset = ({ assetId, handleCancel }: CreateAssetProps) => {
  const { handleCreate, isLoading } = useCreateAsset()

  const handleSubmit = async (data: AssetSchema) => {
    await handleCreate(data, assetId)
  }

  return (
    <Row>
      <Column md='24' lg='24' xl='24'>
        <AssetForm
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isDisabled={isLoading}
        />
      </Column>
    </Row>
  )
}
