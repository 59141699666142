import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  @media ${media.medium} {
    flex-direction: row;
    align-items: center;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`

export const Services = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
  margin-top: 12px;
  @media ${media.medium} {
    justify-content: flex-end;
    margin-top: 0px;
    align-items: end;
  }
`

export const Actions = styled.section`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  right: 5px;

  @media ${media.medium} {
    justify-content: flex-end;
  }
`

export const Content = styled.div`
  padding: 16px;
`

export const IconButton = styled.button`
  background: none;
  border: none;

  display: flex;

  cursor: pointer;

  color: ${({ theme }) => theme.palette.error.main};

  &:hover {
    color: ${({ theme }) => theme.palette.error.darker};
  }
`

export const ConfigButton = styled.button`
  background: none;
  border: none;

  display: flex;

  cursor: pointer;

  color: ${({ theme }) => theme.palette.gray[600]};

  &:hover {
    color: ${({ theme }) => theme.palette.gray[500]};
  }
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
