import { MapPinSimpleArea, Engine, Folder } from "@phosphor-icons/react"
import { FlexContainer } from "../../../../../../../../components/ui/FlexContainer"

const getIconByTypeId = (typeId: number) => {
  switch (typeId) {
    case 1:
      return MapPinSimpleArea
    case 2:
      return Folder
    case 3:
      return MapPinSimpleArea
    case 4:
      return Engine
    default:
      return Folder
  }
}

export const AssetIcon = ({ typeId }: { typeId: number }) => {
  const Icon = getIconByTypeId(typeId)

  return (
    <FlexContainer align='center'>
      <Icon size={20} />
    </FlexContainer>
  )
}
