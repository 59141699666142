import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { Sensor } from "../../../../store/api/sensors/entities"

export const filterSensors = (
  { name, serialNumber, services }: Sensor,
  search: string,
  selectedServices: HDR_SERVICES_TYPE[]
) => {
  if (!findBySearch(search, name, serialNumber)) return false

  return findByFilter(selectedServices, services)
}

const findBySearch = (search: string, name: string, serialNumber: string) => {
  if (!search) return true

  const findByName = name && name.toLowerCase().includes(search.toLowerCase())
  const findBySerialNumber = serialNumber.includes(search)

  return findByName || findBySerialNumber
}

const findByFilter = (
  selectedServices: HDR_SERVICES_TYPE[],
  services: { name: string; type: HDR_SERVICES_TYPE; disabled: boolean }[]
) => {
  if (!selectedServices.length) return true

  for (let i = 0; i < selectedServices.length; i++) {
    const type = selectedServices[i]

    const findService = services.find((service) => service.type === type)

    if (!findService || findService.disabled) return false
  }

  return true
}
