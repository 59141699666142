import { HTMLAttributes, ReactNode, useEffect, useState } from "react"
import { TreeItemIndex, TreeItemRenderContext } from "react-complex-tree"
import { AssetIcon } from "../AssetIcon"
import {
  AddAssetButton,
  ContainerWithChildren,
  Content,
  ExpandAssetButton,
  Wrapper,
  ItemContainer,
} from "./styles"
import { BsPlus } from "react-icons/bs"
import { CaretDown } from "phosphor-react"
import { ModalHedro } from "../../../../../../../../components/ModalHedro"
import { CreateAsset } from "../../../../../Create"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"

interface TreeItemRendererProps
  extends Omit<HTMLAttributes<HTMLLIElement>, "title"> {
  itemIdx: TreeItemIndex
  context: TreeItemRenderContext<"expandedItems">
  depth: number
  children?: ReactNode
  type: number
  childrenAsset: TreeItemIndex[] | undefined
  label: string
  handleAsset: (id: number) => void
}

export const TreeItemRenderer = ({
  itemIdx,
  context,
  children,
  childrenAsset,
  type,
  label,
  depth,
  handleAsset,
}: TreeItemRendererProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    () => localStorage.getItem(`expanded-${itemIdx}`) === "true"
  )

  useEffect(() => {
    localStorage.setItem(`expanded-${itemIdx}`, String(isExpanded))

    if (isExpanded && !context.isExpanded) {
      context.expandItem()
    } else if (!isExpanded && context.isExpanded) {
      context.collapseItem()
    }
  }, [isExpanded, context, itemIdx])

  const handleSelectItem = () => {
    handleAsset(Number(itemIdx))
    context.selectItem()
  }

  const handleExpandItem = () => {
    setIsExpanded((prev) => !prev)
  }

  const hasChildren = !!(childrenAsset && childrenAsset.length)

  const { assets } = useAppTranslate()

  const [openModalCreate, setOpenModalCreate] = useState(false)

  return (
    <Wrapper {...context.itemContainerWithChildrenProps}>
      {openModalCreate && (
        <ModalHedro
          onClose={() => setOpenModalCreate(false)}
          title={assets.modal.createAsset}
          size='xl'
        >
          <CreateAsset
            assetId={Number(itemIdx)}
            handleCancel={() => setOpenModalCreate(false)}
          />
        </ModalHedro>
      )}
      <ItemContainer
        {...context.itemContainerWithoutChildrenProps}
        $selected={context.isSelected ?? false}
        onClick={(e) => {
          handleSelectItem()
        }}
      >
        <Content
          {...context.interactiveElementProps}
          $depth={depth}
          onClick={() => {}}
        >
          {hasChildren && context.isExpanded !== undefined && (
            <ExpandAssetButton $expanded={isExpanded}>
              <CaretDown
                size={16}
                onClick={(e) => {
                  handleExpandItem()
                  e.stopPropagation()
                }}
              />
            </ExpandAssetButton>
          )}

          <AssetIcon typeId={type} />
          {label}
        </Content>
        <AddAssetButton>
          <BsPlus
            size={20}
            onClick={(e) => {
              e.stopPropagation()
              setOpenModalCreate(true)
            }}
          />
        </AddAssetButton>
      </ItemContainer>
      {isExpanded && <ContainerWithChildren>{children}</ContainerWithChildren>}
    </Wrapper>
  )
}
