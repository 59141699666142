import { ApiDataDTO } from "../../features/reports/reports.interfaces"
import { ApiSensorsProvExternal, Sensor } from "./entities"
import { apiCall } from "../../../utils/axios"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { isDateInMilliseconds } from "../../../utils/date"

export async function buildSensorWithDataObject(
  response: ApiSensorsProvExternal[]
): Promise<Sensor[]> {
  const services = Object.keys(HDR_SERVICES_TYPE)

  const obj = buildSensorsProvObject(response)

  const macs: string[] = Object.keys(obj)

  if (macs.length === 0) return []

  let sensors = [] as Sensor[]

  for (let index = 0; index < macs.length; index++) {
    const mac = macs[index]

    const { id, name, sensor } = obj[mac]

    const algorithms = Object.keys(sensor.algorithms).filter(
      (key) => services.includes(key) && sensor.algorithms[key]
    )

    const newMac = {
      serialNumber: sensor.productionSerialNumber,
      id,
      name,
      mac,
      services: [],
      algorithms,
    }

    sensors.push(newMac)
  }

  const sensorsWithServices = await getAvailablesServiceForEachSensor(
    macs,
    sensors
  )

  return sensorsWithServices
}

const buildSensorsProvObject = (response: ApiSensorsProvExternal[]) => {
  const obj: Record<string, ApiSensorsProvExternal> = {}

  for (const prov of response) {
    if (!obj[prov.bleHdrMac]) {
      obj[prov.bleHdrMac] = prov
    }
  }

  return obj
}

const _checkSensorsWithData = async (
  macs: string[],
  service: string
): Promise<ApiDataDTO[]> => {
  const CONVERT_SENSOR_SERVICE_TO_API_CALL: Record<string, string> = {
    temp: "/v1/temps",
    health: "/v1/healths",
    tempsMMM: "/v1/tempsmmm",
    rms2: "/v1/rms2s",
    rmms: "/v1/rmms",
    tilt: "/v1/tilts",
    fft: "/v1/ffts",
    accRaw: "/v1/accRaws",
    _4t20: "/v1/_4t20",
    ntc: "/v1/ntcs",
    pot: "/v1/pots",
  }

  const api = apiCall()

  const url: string | undefined = CONVERT_SENSOR_SERVICE_TO_API_CALL[service]

  const data = await api.get(url!, {
    headers: {
      macs: macs,
    },
  })
  return data.data
}

const getServiceType = (service: string): HDR_SERVICES_TYPE | undefined => {
  switch (service) {
    case "temp":
      return HDR_SERVICES_TYPE.temp
    case "health":
      return HDR_SERVICES_TYPE.health
    case "tempMMM":
      return HDR_SERVICES_TYPE.tempMMM
    case "rms2":
      return HDR_SERVICES_TYPE.rms2
    case "rmms":
      return HDR_SERVICES_TYPE.rmms
    case "tilt":
      return HDR_SERVICES_TYPE.tilt
    case "fft":
      return HDR_SERVICES_TYPE.fft
    case "accRaw":
      return HDR_SERVICES_TYPE.accRaw
    case "_4t20":
      return HDR_SERVICES_TYPE._4t20
    case "ntc":
      return HDR_SERVICES_TYPE.ntc
    case "pot":
      return HDR_SERVICES_TYPE.pot
  }
}

const getAvailablesServiceForEachSensor = async (
  macs: string[],
  availableServices: Sensor[]
) => {
  const allServices = [
    "accRaw",
    "fft",
    "ntc",
    "rmms",
    "rms2",
    "temp",
    "tilt",
    "_4t20",
    "pot",
    "health",
  ]

  for (let j = 0; j < allServices.length; j++) {
    const service = allServices[j]

    const type = getServiceType(service)

    if (type === undefined) {
      console.error("Invalid service type", service)
      continue
    }

    const data: ApiDataDTO[] = await _checkSensorsWithData(macs, service)

    for (let k = 0; k < data.length; k++) {
      const element = data[k]
      let index = availableServices.findIndex((mac) => mac.mac === element.mac)

      if (index !== -1) {
        const lastData = isDateInMilliseconds(element.time)
          ? new Date(element.time).toISOString()
          : new Date(element.time * 1000).toISOString()

        availableServices[index].services = [
          ...availableServices[index].services,
          {
            type,
            name: service,
            disabled: false,
            lastData,
          },
        ]
      }
    }

    for (let i = 0; i < availableServices.length; i++) {
      const sensor = availableServices[i]

      const findAlgo = sensor.algorithms.find((algo) => algo === service)

      if (!findAlgo) continue

      const findService = sensor.services.find(
        (sensorService) => sensorService.name === service
      )

      if (!findService) {
        sensor.services = [
          ...sensor.services,
          { name: service, type, disabled: true },
        ]
      }
    }
  }

  for (let i = 0; i < availableServices.length; i++) {
    const sensor = availableServices[i]

    if (!sensor.services.find((s) => s.type === HDR_SERVICES_TYPE.health)) {
      sensor.services = [
        ...sensor.services,
        { name: "health", type: HDR_SERVICES_TYPE.health, disabled: true },
      ]
    }
  }

  return availableServices
}
