import { Skeleton } from "@mui/material"
import { TitleAndTextSkeleton } from "../../../../../../components/TitleAndText"
import { AssetHeader, Container, ImageAndDescription } from "../styles"
import { ImageContainerSkeleton } from "../../ImageContainer"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"

export const AssetDescriptionSkeleton = () => {
  return (
    <Container>
      <AssetHeader>
        <Skeleton variant='rounded' width={150} />
        <FlexContainer direction='row' gap={10}>
          <Skeleton variant='rounded' width={80} height={40} />
          <Skeleton variant='rounded' width={35} height={30} />
        </FlexContainer>
      </AssetHeader>
      <ImageAndDescription>
        <ImageContainerSkeleton />
        <TitleAndTextSkeleton />
      </ImageAndDescription>
    </Container>
  )
}
