import styled from "styled-components"

export const Wrapper = styled.div`
  &:hover * {
    display: flex;
  }

  display: flex;
  flex-direction: row;
`

export const Container = styled.div<{
  $isConfigured: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid;
  border-radius: 50%;
  border-color: ${({ $isConfigured, theme }) =>
    $isConfigured ? theme.palette.success.main : theme.palette.gray[400]};
  background-color: ${({ $isConfigured, theme }) =>
    $isConfigured ? theme.palette.success.lightest : theme.palette.gray[100]};
  font-size: 16px;
  font-weight: bold;

  svg {
    color: ${({ $isConfigured, theme }) =>
      $isConfigured ? theme.palette.success.darker : theme.palette.gray[500]};
  }
`

export const TooltipWrapper = styled.div`
  position: absolute;
  display: none;
`

export const Tooltip = styled.div`
  position: absolute;
  top: 36px;
  left: -36px;
  white-space: nowrap;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  padding: 8px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`
