import { FlexContainer } from "../FlexContainer"
import { Text } from "../Text"
import { Container, IconContainer } from "./styles"

interface ToastAlarmProps {
  backgroundColor: string
  icon: React.ReactNode
  title: string
  content: Record<string, string>
}

export const ToastAlarm = ({
  backgroundColor,
  icon,
  title,
  content,
}: ToastAlarmProps) => {
  return (
    <Container $color={backgroundColor}>
      <IconContainer>{icon}</IconContainer>
      <FlexContainer align='start' fullWidth>
        <Text fontSize='lg' fontWeight='bold' color='gray.50'>
          {title}
        </Text>

        <FlexContainer align='start'>
          {Object.entries(content).map(([field, value]) => (
            <FlexContainer key={field} direction='row' gap={4}>
              <Text fontWeight='bold' color='gray.50'>
                {field}:
              </Text>
              <Text color='gray.50'>{value}</Text>
            </FlexContainer>
          ))}
        </FlexContainer>
      </FlexContainer>
    </Container>
  )
}
