import Row from "../../../../components/ui/Row"
import { useFetchAssetDetailsQuery } from "../../../../store/store"
import Column from "../../../../components/ui/Column"
import { AssetSchema } from "../utils/schema"
import { AssetForm, AssetFormSkeleton } from "../components/AssetForm"
import { ErrorMessage } from "../components/ErrorMessage"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { useConfigureAsset } from "./controllers/useConfigureAsset"

interface ConfigureProps {
  id: number
  handleClose: () => void
}

export const ConfigureAsset = ({ id, handleClose }: ConfigureProps) => {
  const {
    data: details,
    isLoading,
    isError,
    refetch,
  } = useFetchAssetDetailsQuery(id)

  const { assets } = useAppTranslate()

  const { handleSubmit, isDisable } = useConfigureAsset()

  const handleFormSubmit = async (data: AssetSchema) => {
    await handleSubmit(data, id)
    handleClose()
  }

  return (
    <>
      <Row>
        <Column md='24' lg='24' xl='24'>
          {details && (
            <AssetForm
              isConfigure
              defaultDetails={details}
              isRoot={details.isRoot}
              handleSubmit={handleFormSubmit}
              handleCancel={handleClose}
              isDisabled={isDisable}
            />
          )}
          {isLoading && <AssetFormSkeleton />}
          {isError && (
            <ErrorMessage
              message={assets.details.error}
              handleReload={refetch}
            />
          )}
        </Column>
      </Row>
    </>
  )
}
