import {
  Lightning,
  Thermometer,
  SquaresFour,
  ChartLineUp,
} from "phosphor-react"
import { Container, TooltipWrapper, Tooltip, Wrapper } from "./styles"
import { ServiceConfigured } from "../../utils/entities/assets"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { Text } from "../ui/Text"
import { useAppTranslate } from "../../translate/useAppTranslate"

const getIconService = (serviceType: HDR_SERVICES_TYPE) => {
  switch (serviceType) {
    case HDR_SERVICES_TYPE.temp:
      return Thermometer
    case HDR_SERVICES_TYPE.rms2:
      return ChartLineUp
    case HDR_SERVICES_TYPE.rmms:
      return Lightning
    case HDR_SERVICES_TYPE.tilt:
      return Lightning
    case HDR_SERVICES_TYPE.fft:
      return ChartLineUp
    case HDR_SERVICES_TYPE.accRaw:
      return Lightning
    case HDR_SERVICES_TYPE._4t20:
      return SquaresFour
    case HDR_SERVICES_TYPE.ntc:
      return Lightning
    case HDR_SERVICES_TYPE.pot:
      return SquaresFour
    default:
      return Lightning
  }
}

export const IconService = ({
  type,
  isConfigured = false,
}: ServiceConfigured) => {
  const { services } = useAppTranslate()

  const Icon = getIconService(type)

  return (
    <Wrapper>
      <Container $isConfigured={isConfigured}>
        <Icon size={14} weight='bold' />
      </Container>
      <TooltipWrapper>
        <Tooltip>
          <Text>{services[type].helpText}</Text>
        </Tooltip>
      </TooltipWrapper>
    </Wrapper>
  )
}
