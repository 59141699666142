import { useUpdateAssetMutation } from "../../../../../store/store"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useMemo } from "react"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useConfigureAsset = () => {
  const { assets } = useAppTranslate()

  const [updateAsset, mutation] = useUpdateAssetMutation()

  const isDisable = useMemo(() => mutation.isLoading, [mutation.isLoading])

  const handleSubmit = async (data: AssetSchema, id: number) => {
    try {
      await updateAsset({
        id,
        data: {
          name: data.name,
          description: data.description,
        },
      })

      toast.success(assets.form.toastSucessConfigure)
    } catch (e) {
      console.log(e)
      toast.error("Erro ao atualizar o asset. Tente novamente.")
    }
  }

  return {
    handleSubmit,
    isDisable,
  }
}
