import { MagnifyingGlass } from "phosphor-react"
import { Input } from "../Input"
import { Form } from "./styles"
import { useRef } from "react"

interface InputSearchProps {
  onChangeSearch: (value: string) => void
  search?: string
  placeholder: string
  emptyLabel?: boolean
}

export const InputSearch = ({
  onChangeSearch,
  search,
  placeholder,
  emptyLabel = false,
}: InputSearchProps) => {
  const searchValue = useRef("")

  const handleSearch = () => {
    onChangeSearch(searchValue.current)
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        handleSearch()
      }}
    >
      <Input
        placeholder={placeholder}
        icon={<MagnifyingGlass size={16} weight='bold' />}
        onClickIcon={handleSearch}
        onChange={(e) => (searchValue.current = e.target.value)}
        emptyLabel={emptyLabel}
      />
    </Form>
  )
}
