import { InputHTMLAttributes, ReactNode, forwardRef } from "react"
import {
  Container,
  ErrorText,
  HelperText,
  Icon,
  Label,
  SInput,
  WrapperIcon,
} from "./styles"
import { InputSkeleton } from "./Input.skeleton"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  emptyLabel?: boolean
  helper?: string
  error?: string
  icon?: ReactNode
  onClickIcon?: (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { label, emptyLabel = false, helper, error, icon, onClickIcon, ...props },
    ref
  ) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && onClickIcon) {
        onClickIcon(event)
      }
    }

    return (
      <Container>
        {label && <Label $disable={props.disabled}>{label}</Label>}

        {emptyLabel && <Label $disable={props.disabled}>&nbsp;</Label>}

        <WrapperIcon $hasOnClickIcon={!!onClickIcon}>
          {icon && (
            <Icon $clickable={!!onClickIcon} onClick={onClickIcon}>
              {icon}
            </Icon>
          )}
          <SInput
            $hasIcon={icon !== undefined}
            $iconRight={!!onClickIcon}
            ref={ref}
            {...props}
            onKeyDown={handleKeyDown}
          />
        </WrapperIcon>

        {error ? (
          <ErrorText>{error}</ErrorText>
        ) : (
          helper && <HelperText>{helper}</HelperText>
        )}
      </Container>
    )
  }
)

Input.displayName = "Input"

export { Input, InputSkeleton }
