import { useState } from "react"
import { Button } from "../../../../../components/ui/Button"
import { Checkbox } from "../../../../../components/ui/Checkbox"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { Text } from "../../../../../components/ui/Text"
import { servicesTypes } from "../../../../../store/api/utils"
import { Tooltip, TooltipWrapper, ServicesGrid } from "./styles"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { getHDRServiceAcronym } from "../../../../../utils/hdr_services_name"

interface FilterServicesProps {
  mobile?: boolean
  open: boolean
  onUpdateSelectedServices: (services: HDR_SERVICES_TYPE[]) => void
}

export const FilterServices = ({
  open,
  mobile = false,
  onUpdateSelectedServices,
}: FilterServicesProps) => {
  const { reports, buttons } = useAppTranslate()

  const [selectedServices, setSelectedServices] = useState<HDR_SERVICES_TYPE[]>(
    []
  )

  const selectService = (service: HDR_SERVICES_TYPE) => {
    setSelectedServices((prev) => [...prev, service])
  }

  const unSelectService = (service: HDR_SERVICES_TYPE) => {
    setSelectedServices((prev) => prev.filter((s) => s !== service))
  }

  const handleChangeCheckbox = (value: boolean, service: HDR_SERVICES_TYPE) => {
    if (value) selectService(service)
    else unSelectService(service)
  }

  const handleCleanFilters = () => {
    setSelectedServices([])
    onUpdateSelectedServices([])
  }

  const getCheckboxState = (service: HDR_SERVICES_TYPE) => {
    return selectedServices.includes(service)
  }

  return (
    <TooltipWrapper $open={open} $mobile={mobile}>
      <Tooltip>
        <FlexContainer align='start' gap={12} fullWidth>
          <FlexContainer align='start' fullWidth>
            <Text fontWeight='semibold' color='gray.600'>
              {reports.filterServices}
            </Text>

            <ServicesGrid>
              {servicesTypes.map((service, index) => (
                <ServiceItem
                  key={service}
                  service={service}
                  onChangeCheckbox={handleChangeCheckbox}
                  getCheckboxState={getCheckboxState}
                />
              ))}
            </ServicesGrid>
          </FlexContainer>

          <FlexContainer direction='row' justify='space-between' fullWidth>
            <Button variant='secondary' onClick={handleCleanFilters}>
              {buttons.clear}
            </Button>
            <Button onClick={() => onUpdateSelectedServices(selectedServices)}>
              {buttons.apply}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Tooltip>
    </TooltipWrapper>
  )
}

export const ServiceItem = ({
  service,
  onChangeCheckbox,
  getCheckboxState,
}: {
  service: HDR_SERVICES_TYPE
  onChangeCheckbox: (value: boolean, service: HDR_SERVICES_TYPE) => void
  getCheckboxState: (service: HDR_SERVICES_TYPE) => boolean
}) => {
  return (
    <FlexContainer direction='row' justify='start' gap={4}>
      <Checkbox
        checked={getCheckboxState(service)}
        onChange={(e) => onChangeCheckbox(e.target.checked, service)}
      />
      <Text>{getHDRServiceAcronym(service).toUpperCase()}</Text>
    </FlexContainer>
  )
}
