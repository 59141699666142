import styled from "styled-components"

export const Container = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;

  background-color: ${({ $color }) => $color};
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.palette.gray[50]};

  height: 60px;
  width: 60px;
`
