import { forwardRef, InputHTMLAttributes } from "react"
import { Container, Input } from "./styles"
import { Text } from "../Text"

interface DatetimeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isEmpty: boolean
  error?: string
  label?: string
}

export const DatetimeInput = forwardRef<HTMLInputElement, DatetimeInputProps>(
  ({ isEmpty, error, label, ...props }, ref) => {
    return (
      <Container>
        {label && (
          <Text color='gray.600' fontSize='xs' fontWeight='semibold'>
            {label}
          </Text>
        )}
        <Input
          {...props}
          $empty={isEmpty}
          ref={ref}
          type='text'
          onFocus={(e) => {
            if (!e.target.value) e.target.type = "datetime-local"
          }}
          onBlur={(e) => {
            if (!e.target.value) e.target.type = "text"
          }}
        />
        {error && (
          <Text color='error.main' fontSize='xs'>
            {error}
          </Text>
        )}
      </Container>
    )
  }
)

DatetimeInput.displayName = "DatetimeInput"
