import { Container } from "./styles"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { useProperties } from "../../utils/useProperties"

interface AssetTypeProps {
  typeId: number
  typeName: string
  createdAt: string
}

export const AssetType = ({ typeId, typeName, createdAt }: AssetTypeProps) => {
  const { assets } = useAppTranslate()

  const { getDefaultPropertyName } = useProperties()

  const name = getDefaultPropertyName(typeId) ?? typeName

  return (
    <Container>
      <Text>{`${assets.details.type}: ${name}`}</Text>
      <Text>{`${assets.details.createdAt}: ${createdAt}`}</Text>
    </Container>
  )
}
