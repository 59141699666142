export const cleanAndPadSerialNumber = (
  serialNumber: string,
  hardwareVersion?: string | null | undefined
): string => {
  let formatedSerial = removeTextPrefix(serialNumber)

  if (!hardwareVersion) return formatedSerial

  if (hardwareVersion === "1.2") {
    if (formatedSerial.length < 5) return formatedSerial.padStart(5, "0")
    return formatedSerial.substring(formatedSerial.length - 5)
  }

  return formatedSerial
}

const removeTextPrefix = (value: string): string => {
  let removed = ""

  for (let index = value.length - 1; index >= 0; index--) {
    if (isNaN(Number(value[index]))) break
    removed = removed.concat(value[index])
  }

  return removed.split("").reverse().join("")
}
