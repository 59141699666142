import { createContext, useMemo, useState } from "react"
import { useForm, UseFormRegister, useWatch } from "react-hook-form"
import { reportsSchema, ReportsSchema } from "../utils/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { sensorRightJson } from "../../../Reports/entities/sensorRightJson"
import { Sensor } from "../../../../store/api/sensors/entities"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { reportRequest } from "../../../../store/features/reports/reports.api"
import { format } from "date-fns"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { HDR_SERVICES_TYPE } from "hdr-process-data"

export interface ReportsContextDTO {
  register: UseFormRegister<{
    orientedBy: "sensor" | "asset"
    startDate: string
    endDate: string
  }>
  handleSearch: (value: string) => void
  handleDownloadReport: () => void
  handleCheckbox: (sensor: Sensor, service: string, checked: boolean) => void
  handleCleanAllCheckboxes: () => void
  handleUpdateSelectedServices: (services: HDR_SERVICES_TYPE[]) => void
  getCheckboxState: (sensor: Sensor, service: string) => boolean
  hasSensorsSelected: boolean
  isStartDateEmpty: boolean
  isEndDateEmpty: boolean
  isDownloadDisabled: boolean
  search: string
  selectedServices: HDR_SERVICES_TYPE[]
}

export const ReportsContext = createContext<ReportsContextDTO | null>(null)

export const ReportsContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { reports } = useAppTranslate()

  const dispatch = useAppDispatch()

  const { control, register, handleSubmit } = useForm<ReportsSchema>({
    resolver: zodResolver(reportsSchema),
    defaultValues: {
      orientedBy: "sensor",
    },
  })

  const { isLoadingData } = useAppSelector(
    (state) => state.persistedReducer.reports
  )

  const startDate = useWatch({ control, name: "startDate" })
  const endDate = useWatch({ control, name: "endDate" })

  const isStartDateEmpty = !startDate
  const isEndDateEmpty = !endDate

  const [search, setSearch] = useState("")

  const [sensors, setSensors] = useState<sensorRightJson>({})

  const hasSensorsSelected = !!Object.keys(sensors).length

  const isDownloadDisabled = useMemo(
    () =>
      isStartDateEmpty ||
      isEndDateEmpty ||
      !Object.keys(sensors).length ||
      isLoadingData,
    [isStartDateEmpty, isEndDateEmpty, isLoadingData, sensors]
  )

  const [selectedServices, setSelectedServices] = useState<HDR_SERVICES_TYPE[]>(
    []
  )

  const handleUpdateSelectedServices = (services: HDR_SERVICES_TYPE[]) => {
    setSelectedServices([...services])
  }

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  const getCheckboxState = (sensor: Sensor, service: string) => {
    if (!sensors[sensor.mac]) return false

    return sensors[sensor.mac].services.includes(service)
  }

  const handleCleanAllCheckboxes = () => {
    setSensors({})
  }

  const handleCheckbox = (
    sensor: Sensor,
    service: string,
    checked: boolean
  ) => {
    if (checked) {
      selectService(sensor, service)
    } else {
      unselectService(sensor, service)
    }
  }

  const selectService = (sensor: Sensor, service: string) => {
    setSensors((prev) => {
      if (!prev[sensor.mac]) {
        return {
          ...prev,
          [sensor.mac]: {
            id: sensor.id.toString(),
            name: sensor.name,
            serialNumber: sensor.serialNumber,
            services: [service],
          },
        }
      }

      return {
        ...prev,
        [sensor.mac]: {
          ...prev[sensor.mac],
          services: [...prev[sensor.mac].services, service],
        },
      }
    })
  }

  const unselectService = (sensor: Sensor, service: string) => {
    setSensors((prev) => {
      if (sensors[sensor.mac].services.length === 1) {
        delete prev[sensor.mac]
        return { ...prev }
      }

      return {
        ...prev,
        [sensor.mac]: {
          ...prev[sensor.mac],
          services: prev[sensor.mac].services.filter((s) => s !== service),
        },
      }
    })
  }

  const handleDownloadReport = () => {
    handleSubmit(({ startDate, endDate }) => {
      dispatch(
        reportRequest({
          startDate: format(startDate, "yyy-MM-dd"),
          endDate: format(endDate, "yyy-MM-dd"),
          sensors,
        })
      )
        .unwrap()
        .then((result) => {
          if (result === null) {
            toast.warning(reports.toast.warning)
            return
          }

          toast.success(reports.toast.success)
        })
        .catch(() => toast.error(reports.toast.error))
    })()
  }

  const defaultContext: ReportsContextDTO = {
    register,
    handleSearch,
    handleDownloadReport,
    handleCheckbox,
    handleCleanAllCheckboxes,
    handleUpdateSelectedServices,
    getCheckboxState,
    hasSensorsSelected,
    isStartDateEmpty,
    isEndDateEmpty,
    isDownloadDisabled,
    search,
    selectedServices,
  }

  return (
    <ReportsContext.Provider value={defaultContext}>
      {children}
    </ReportsContext.Provider>
  )
}
