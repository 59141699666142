import { InputHTMLAttributes, useState } from "react"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { DatetimeInput } from "../../../../../components/ui/DatetimeInput"
import { AssetSensorsTable } from "../AssetSensorsTable"
import { InputSearch } from "../../../../../components/ui/InputSearch"

interface LinkSensorFormProps {
  onCheck: (id: number | undefined) => void
  dateInputProps: InputHTMLAttributes<HTMLInputElement>
  dateInputError: string | undefined
  dateInputIsEmpty: boolean
}

export const LinkSensorForm = ({
  onCheck,
  dateInputProps,
  dateInputError,
  dateInputIsEmpty,
}: LinkSensorFormProps) => {
  const { assets } = useAppTranslate()

  const [search, setSearch] = useState("")

  const handleSearch = (value: string) => {
    setSearch(value)
  }

  return (
    <FlexContainer gap={12} fullWidth>
      <FlexContainer direction='row' align='start' gap={12} fullWidth>
        <InputSearch
          onChangeSearch={handleSearch}
          placeholder={assets.sensors.search}
          emptyLabel
        />

        <DatetimeInput
          label={assets.form.sensors.linkedAt}
          isEmpty={dateInputIsEmpty}
          error={dateInputError}
          type='datetime-local'
          placeholder={assets.form.sensors.linkedAt}
          {...dateInputProps}
        />
      </FlexContainer>

      <AssetSensorsTable search={search} handleCheck={onCheck} />
    </FlexContainer>
  )
}
