import { Column } from "../.."
import { HeaderCell } from "../../table.styled"
import { SortContainer } from "./styles"
import { Text } from "../../../ui/Text"
import { ArrowDown, ArrowUp } from "phosphor-react"

interface HeaderTitleProps<T> {
  column: Column<T>
  selected: boolean
  sortAsc: boolean
  handleChangeOrderBy: (orderBy: string) => void
}

export const HeaderTitle = <T,>({
  column,
  selected,
  sortAsc,
  handleChangeOrderBy,
}: HeaderTitleProps<T>) => {
  const handleSort = () => {
    handleChangeOrderBy(column.id)
  }

  if (!column.order)
    return (
      <HeaderCell>
        <SortContainer $selected={selected}>
          {column.icon}
          <Text color='gray.800' fontSize='sm' fontWeight='medium'>
            {column.label}
          </Text>
        </SortContainer>
      </HeaderCell>
    )

  return (
    <HeaderCell>
      <SortContainer $selected={selected}>
        <Text color='gray.800' fontSize='sm' fontWeight='medium'>
          {column.label}
        </Text>

        {sortAsc && selected ? (
          <ArrowUp size={16} onClick={handleSort} />
        ) : (
          <ArrowDown size={16} onClick={handleSort} />
        )}
      </SortContainer>
    </HeaderCell>
  )
}
