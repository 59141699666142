import { Skeleton } from "@mui/material"
import {
  AssetInfo,
  BasicInfo,
  PropertiesFields,
  PropertiesContainer,
} from "./styles"
import { Divider } from "../../../../../components/ui/Divider"
import { Column, TableSkeleton } from "../../../../../components/TableSkeleton"
import { InputSkeleton } from "../../../../../components/ui/Input"

export interface SensorRow {
  id: number | string
  name: string
  serialNumber: string
}

export const AssetFormSkeleton = () => {
  const columns: Column<SensorRow>[] = [
    {
      label: "Nome",
      render: ({ name }) => name,
    },
    {
      label: "Número de série",
      render: ({ serialNumber }) => serialNumber,
    },
  ]

  return (
    <>
      <AssetInfo>
        <Skeleton variant='rectangular' width={273} height={200} />

        <BasicInfo>
          <InputSkeleton />
          <InputSkeleton />
        </BasicInfo>
      </AssetInfo>

      <Divider />

      <>
        <Skeleton variant='text' width={64} height={24} />
        <Skeleton variant='rounded' height={156} />
      </>

      <Divider />

      <PropertiesContainer>
        <PropertiesFields>
          <InputSkeleton />
          <InputSkeleton />
          <InputSkeleton />
        </PropertiesFields>
      </PropertiesContainer>

      <Divider />

      <div style={{ padding: "0 16px" }}>
        <TableSkeleton columns={columns} checkbox={true} data={5} />
      </div>
    </>
  )
}
