import { Title } from "../../../../../components/Title"
// import { Button } from "../../../../../components/ui/Button"
import { Container, TitleArea } from "./styles"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { ModalConfigureSensor } from "../ModalConfigureSensor"
import { useState } from "react"
import { Button } from "../../../../../components/ui/Button"

interface AssetWithoutSensorProps {
  assetId: number
}

export const AssetWithoutSensor = ({ assetId }: AssetWithoutSensorProps) => {
  const { assets } = useAppTranslate()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      {isModalOpen && (
        <ModalConfigureSensor
          handleCloseModal={handleCloseModal}
          assetId={assetId}
        />
      )}

      <TitleArea>
        <Title label='Sensor' size='sm' weight='medium' />
      </TitleArea>
      <Container>
        <Text fontSize='sm' color='gray.500' fontWeight='regular'>
          {assets.sensors.emptyLabel}
        </Text>
        <Button variant='link' onClick={handleOpenModal}>
          {assets.sensors.associateSensor}
        </Button>
      </Container>
    </>
  )
}
