import styled from "styled-components"
import { media } from "../../../utils/media-queries"

export const Label = styled.p<{ $disable: boolean | undefined }>`
  color: ${({ $disable, theme }) =>
    $disable ? theme.palette.gray[500] : theme.palette.gray[600]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 2px;

  button {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.xs.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightSemibold};
    line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
  }

  @media ${media.small} {
    max-width: 300px;
  }
`

export const Select = styled.select`
  height: 48px;

  padding: 2px 0 0 10px;

  background-color: ${({ theme }) => theme.palette.gray[50]};

  outline: none;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.palette.gray[300]};
  border-radius: 4px;

  &:required:invalid {
    color: ${(props) => props.theme.palette.gray[500]};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.md.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    line-height: ${(props) => props.theme.typography.text.md.lineHeight};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &:disabled {
    &::placeholder {
      color: ${(props) => props.theme.palette.gray[400]};
    }

    p {
      color: ${(props) => props.theme.palette.gray[500]};
    }
  }
`

export const Option = styled.option`
  &[value=""][disabled] {
    color: ${({ theme }) => theme.palette.gray[400]};
  }

  color: ${({ theme }) => theme.palette.gray[600]};

  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
`

export const ErrorText = styled.p`
  padding-top: 2px;
  color: ${(props) => props.theme.palette.error.main};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.xs.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  line-height: ${(props) => props.theme.typography.text.xs.lineHeight};
`
