import { DownloadSimple, Funnel } from "phosphor-react"
import { DatetimeInput } from "../../../../../components/ui/DatetimeInput"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { InputSelect } from "../../../../../components/ui/InputSelect"
import theme from "../../../../../styles/theme"
import { Container, InputWrapper, InputsContainer } from "./styles"
import { IconButton } from "../../../../../components/ui/IconButton"
import { ReportsContext, ReportsContextDTO } from "../../context/ReportsContext"
import { useContext, useState } from "react"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { InputSearch } from "../../../../../components/ui/InputSearch"
import { FilterServices } from "../FilterServices"

export const HeaderActions = () => {
  const { reports } = useAppTranslate()

  const {
    register,
    handleSearch,
    handleDownloadReport,
    handleUpdateSelectedServices,
    isStartDateEmpty,
    isEndDateEmpty,
    isDownloadDisabled,
  } = useContext(ReportsContext) as ReportsContextDTO

  const [open, setOpen] = useState(false)

  return (
    <Container>
      <InputsContainer>
        <InputWrapper>
          <InputSelect options={["Sensor"]} />
        </InputWrapper>

        <InputWrapper>
          <DatetimeInput
            placeholder={reports.startDatePlaceholder}
            isEmpty={isStartDateEmpty}
            {...register("startDate")}
          />
        </InputWrapper>

        <InputWrapper>
          <DatetimeInput
            placeholder={reports.endDatePlaceholder}
            isEmpty={isEndDateEmpty}
            {...register("endDate")}
          />
        </InputWrapper>
      </InputsContainer>

      <FlexContainer fullWidth gap={12}>
        <FlexContainer
          direction='row'
          align='end'
          justify='end'
          gap={8}
          fullWidth
        >
          <InputWrapper>
            <InputSearch
              onChangeSearch={handleSearch}
              placeholder={reports.searchSensorPlaceholder}
            />
          </InputWrapper>

          <IconButton
            variant='secondary'
            onClick={() => setOpen((prev) => !prev)}
            icon={<Funnel size={20} />}
          />

          <FilterServices
            open={open}
            onUpdateSelectedServices={handleUpdateSelectedServices}
          />

          <IconButton
            onClick={handleDownloadReport}
            disabled={isDownloadDisabled}
            icon={
              <DownloadSimple
                size={20}
                weight='bold'
                color={theme.palette.gray[50]}
              />
            }
          />
        </FlexContainer>

        <FilterServices
          open={open}
          onUpdateSelectedServices={handleUpdateSelectedServices}
          mobile
        />
      </FlexContainer>
    </Container>
  )
}
