import { useNavigate } from "react-router-dom"
import { useCreateAssetMutation } from "../../../../../store/store"

import { useMemo } from "react"
import { AssetSchema } from "../../utils/schema"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useCreateAsset = () => {
  const { assets } = useAppTranslate()
  const navigate = useNavigate()

  const [createAsset, mutation] = useCreateAssetMutation()

  const isLoading = useMemo(() => mutation.isLoading, [mutation.isLoading])

  const handleCreate = async (data: AssetSchema, parentId: number) => {
    if (!parentId) return

    try {
      const asset = await createAsset({
        parentId,
        data,
      }).unwrap()

      navigate(`/assets/${asset.id}`)

      toast.success(assets.form.toastSucessCreate)
    } catch (error) {
      toast.error(assets.form.toastErrorCreate)
      console.error(error)
    }
  }

  return {
    handleCreate,
    isLoading,
  }
}
