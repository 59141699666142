import styled from "styled-components"
import { media } from "../../../../utils/media-queries"

export const Container = styled.footer`
  display: none;

  height: min-content;

  @media ${media.small} {
    background-color: ${({ theme }) => theme.palette.gray[50]};
    display: table-footer-group;
    position: sticky;
    bottom: 0;
    padding-left: 16px;
    padding-right: 16px;

    .pagination {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 16px;
    }
  }
`

export const Selector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: min-content;

  white-space: nowrap;

  gap: 4px;

  p {
    color: ${(props) => props.theme.palette.gray[700]};
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: ${(props) => props.theme.typography.text.sm.fontSize};
    font-weight: ${(props) => props.theme.typography.fontWeightRegular};
    line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
  }

  select {
    border-color: ${({ theme }) => theme.palette.gray[300]};
    color: ${({ theme }) => theme.palette.gray[500]};
    background-color: ${({ theme }) => theme.palette.gray[50]};
  }
`

export const Pages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: min-content;
`

export const Counter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: min-content;

  gap: 2px;

  margin-left: 4px;
  margin-right: 4px;
`

export const Item = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  cursor: default;

  color: ${({ theme }) => theme.palette.gray[500]};

  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
`

export const Page = styled(Item)<{ $selected?: boolean }>`
  cursor: pointer;

  color: ${({ $selected = false, theme }) =>
    $selected ? theme.palette.gray[700] : theme.palette.gray[500]};
`
