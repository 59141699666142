import styled, { css } from "styled-components"
import { media } from "../../utils/media-queries"

const tdStyles = css`
  position: relative;

  padding-left: calc(50% + 16px);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.palette.gray[50]};

  border-left: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-right: 1px solid ${(props) => props.theme.palette.gray[400]};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray[400]};
`

const beforeStyles = css`
  content: attr(data-label);

  position: absolute;
  left: 0;

  height: 100%;
  width: 50%;

  display: flex;
  align-items: center;
  justify-content: start;

  padding-left: 16px;

  color: ${(props) => props.theme.palette.gray[800]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  line-height: ${(props) => props.theme.typography.text.sm.lineHeight};

  background-color: ${(props) => props.theme.palette.gray[200]};
`

export const Root = styled.table<{ $checkbox: boolean; $expand: boolean }>`
  width: 100%;

  thead {
    display: none;
  }

  tbody,
  tr {
    display: block;
    width: 100%;
  }

  td {
    display: table-cell;
  }

  tr {
    margin-bottom: 12px;
  }

  ${(props) =>
    props.$checkbox &&
    css`
      td + td::before {
        ${beforeStyles}
      }
    `}

  ${(props) =>
    !props.$checkbox &&
    css`
      td::before {
        ${beforeStyles}
      }
    `}

  td + td {
    border-top: none;
  }

  td:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${(props) => props.theme.palette.gray[400]};

    ${(props) =>
      props.$checkbox &&
      css`
        background-color: ${(props) => props.theme.palette.gray[50]};
      `}
  }

  td:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    ${({ $expand }) =>
      $expand &&
      css`
        padding-left: 0px;
      `}
  }

  ${({ $expand }) =>
    $expand &&
    css`
      td:last-child::before {
        display: none;
      }
    `}

  ${(props) =>
    props.$checkbox &&
    css`
      td + td {
        ${tdStyles}
      }
    `}

  ${(props) =>
    !props.$checkbox &&
    css`
      td {
        ${tdStyles}
      }
    `}


  @media ${media.small} {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    max-width: 100%;
    table-layout: auto;

    thead {
      display: table-header-group;
    }

    tbody {
      display: table-row-group;
    }

    tr {
      display: table-row;
    }

    td {
      display: table-cell;
      border: none;

      padding-left: 16px;
    }

    th {
      padding-left: 16px;
    }

    ${(props) =>
      props.$checkbox &&
      css`
        td + td::before {
          display: none;
          position: inherit;
          padding: 0px;
        }
      `}

    ${(props) =>
      !props.$checkbox &&
      css`
        td::before {
          position: inherit;
          display: none;
        }
      `}


    ${(props) =>
      props.$checkbox &&
      css`
        td + td {
          display: table-cell;
          padding-left: 16px;
          border: none;
        }
      `}
      
    td:first-child {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      display: table-cell;
      border: none;

      ${(props) =>
        props.$checkbox &&
        css`
          background-color: ${(props) => props.theme.palette.gray[50]};
        `}
    }
  }
`

export const Header = styled.thead`
  width: 100%;

  background-color: ${(props) => props.theme.palette.gray[200]};

  tr {
    border: none;
  }
`

export const HeaderRow = styled.tr<{ $rowsize: number }>`
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.palette.gray[200]};
  border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};

  @media ${media.small} {
    th:first-child {
      padding-left: 16px;
      width: auto;
    }

    th:last-child {
      padding-right: 16px;
    }

    th:nth-child(n + 2) {
    }
  }
`

export const HeaderCell = styled.th`
  padding-top: 12px;
  padding-bottom: 12px;

  text-align: start;
`

export const Container = styled.div`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: end;

  height: 100%;
  width: 100%;

  @media ${media.small} {
    border: 1px solid ${(props) => props.theme.palette.gray[400]};
    border-radius: 4px;
    background-color: ${(props) => props.theme.palette.gray[100]};
    display: grid;
    grid-template-rows: auto 1fr;
    overflow-y: auto;
  }
`

export const Body = styled.tbody`
  height: 100%;
  width: 100%;
`

export const EmptyBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;

  height: 100%;
`
