import styled from "styled-components"
import { media } from "../../utils/media-queries"

export const PageWrapper = styled.div`
  @media ${media.small} {
    height: 100vh;
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
  }
`

export const PageContent = styled.div`
  flex: 1;

  margin: 0px 12px 12px 12px;

  overflow: hidden;
`
