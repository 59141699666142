import styled from "styled-components"

export const DropdownContainer = styled.div<{
  width: string
  fontWeight: string
}>`
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width ?? "100%"};
  justify-content: space-around;
  align-items: center;

  gap: 1%;
  margin-bottom: 1%;
  font-weight: ${(props) => props.fontWeight ?? "bold"};
`

export const DropdownTitle = styled.div`
  width: 110%;
`

export const DropDownAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
`

export const StyledDropdown = styled.div`
  display: flex;
  width: 100%;
  height: 23px;
  position: relative;
  justify-content: center;
  border: none;
  border-bottom: 1px solid grey;
`

export const DropdownTextSelected = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const DropdownList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 23px;
  z-index: 10;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  max-height: 400px;
  overflow-y: auto;
`

export const DropdownListItem = styled.button<{ index: number }>`
  background: ${(props) => (props.index % 2 === 0 ? "#f0ebeb" : "#d8d3d3")};
  border: none;
  cursor: pointer;
  padding: 2%;
  border-radius: 0px !important;

  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background: #9ffecb;
  }
`
