import { ModalHedro } from "../../../../components/ModalHedro"
import { Input } from "../../../../components/ui/Input"
import { Checkbox } from "../../../../components/ui/Checkbox"
import { FlexContainer } from "../../../../components/ui/FlexContainer"
import { Text } from "../../../../components/ui/Text"
import { ChartSchema } from "../../context/CreateChartContext"
import { InputSelect } from "../../../../components/ui/InputSelect"
import { InputChartType } from "./components/InputChartType"
import { useConfigureChart } from "./hooks/useConfigureChart"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ProtectedComponent } from "../../../../utils/authentication/ProtectedComponent"
import { PermissionsProteu } from "../../../../store/features/user/user.interfaces"

interface ModalConfigureChartProps {
  onClose: () => void
  handleSubmitData: (data: ChartSchema) => void
  defaultValues?: ChartSchema
}

export const ModalConfigureChart = ({
  onClose,
  handleSubmitData,
  defaultValues,
}: ModalConfigureChartProps) => {
  const {
    dashboard: { configureChart },
  } = useAppTranslate()

  const {
    handleSubmit,
    register,
    setValue,
    getErrorMessage,
    renderPeriodLabel,
    errors,
    type,
    periods,
    orientedBy,
  } = useConfigureChart({ defaultValues })

  return (
    <ModalHedro
      onClose={onClose}
      onCancel={onClose}
      onNext={handleSubmit(handleSubmitData)}
      title={configureChart.title}
      subtitle={configureChart.subtitle}
      size='lg'
    >
      <FlexContainer gap={16} align='start'>
        <Input
          label={configureChart.nameLabel}
          placeholder={configureChart.namePlaceholder}
          error={getErrorMessage(errors.title?.message)}
          {...register("title")}
        />

        <FlexContainer gap={12} align='start'>
          <Text fontWeight='semibold' color='gray.600'>
            {configureChart.orientedByLabel}
          </Text>
        </FlexContainer>

        <FlexContainer direction='row' align='start' gap={8}>
          <Checkbox
            type='radio'
            onChange={() => setValue("orientedBy", "sensor")}
            checked={orientedBy === "sensor"}
          />
          <Text fontWeight='medium' color='gray.700'>
            {configureChart.sensor}
          </Text>

          <ProtectedComponent permissions={[PermissionsProteu.AssetsEnable]}>
            <Checkbox
              type='radio'
              onChange={() => setValue("orientedBy", "asset")}
              checked={orientedBy === "asset"}
            />
            <Text fontWeight='medium' color='gray.700'>
              {configureChart.asset}
            </Text>
          </ProtectedComponent>
        </FlexContainer>

        <FlexContainer gap={12} align='start'>
          <Text fontWeight='semibold' color='gray.600'>
            {configureChart.typeLabel}
          </Text>

          <InputChartType
            title={configureChart.amplitudeTimeS}
            subtitle={configureChart.amplitudeTimeSText}
            onChange={() => setValue("type", 1)}
            checked={type === 1}
          />

          <InputChartType
            title={configureChart.amplitudeFrequency}
            subtitle={configureChart.amplitudeFrequencyText}
            onChange={() => setValue("type", 2)}
            checked={type === 2}
          />

          <InputChartType
            title={configureChart.amplitudeTimeMs}
            subtitle={configureChart.amplitudeTimeMsText}
            onChange={() => setValue("type", 3)}
            checked={type === 3}
          />
        </FlexContainer>

        {type === 1 && (
          <InputSelect
            label={configureChart.periodLabel}
            placeholder={configureChart.periodPlaceholder}
            options={periods}
            renderLabel={renderPeriodLabel}
            error={getErrorMessage(errors.period?.message)}
            {...register("period")}
          />
        )}

        {type !== 1 && <FlexContainer padding='66px 0 0 0 ' />}
      </FlexContainer>
    </ModalHedro>
  )
}
