import { BrowserRouter } from "react-router-dom"
import Router from "./routes"
import { ToastContainer } from "react-toastify"
import styled from "styled-components"

const StyledToastContainer = styled(ToastContainer)`
  font-size: 14px;
  z-index: 99999;

  .toast-adl {
    left: -480px;

    font-size: 24px;

    width: 800px;

    .Toastify__toast-icon {
      width: auto !important;
    }
  }
`

function App() {
  return (
    <BrowserRouter>
      <Router />
      <StyledToastContainer />
    </BrowserRouter>
  )
}

export default App
